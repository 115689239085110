import React, { FC } from "react";

interface BoxProps {
    title: string;
    content: string;
}

export const IconBox: FC<BoxProps> = ({ title, content }) => {
    return (
        <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
            <div className="icon-box">
                <div className="icon"><i className="bx bxl-dribbble"></i></div>
                <h4><a href="">{title}</a></h4>
                <p>{content}</p>
            </div>
        </div>
    );
}