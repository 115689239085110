import React from "react";
import { Container, Nav, NavDropdown, Navbar, Offcanvas } from "react-bootstrap";

const Header = () => {
    return (
        <>
            <header id="header" className="d-flex align-items-center">
                <div className="container d-flex justify-content-between align-items-center">

                    <div className="logo">
                        <h1><a href="/">Suparna</a></h1>
                        {/* <!-- Uncomment below if you prefer to use an image logo -->
        <!-- <a href="index.html"><img src="assets/img/logo.png" alt="" className="img-fluid"></a>--> */}
                    </div>

                    <Navbar expand="lg">
                        <Container>
                            <Navbar.Toggle aria-controls="basic-navbar-nav" />
                            <Navbar.Collapse id="basic-navbar-nav">
                                <Nav id="navbar" className="navbar" as="nav">
                                    <ul>
                                        <Nav.Item as="li">
                                            <Nav.Link href="/">Maintain Health</Nav.Link>
                                        </Nav.Item>
                                        <NavDropdown title="Measure Health" as="ul">
                                            <Nav.Item as="li">
                                                <Nav.Link href="/pro-tools">Professional Tools</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item as="li">
                                                <Nav.Link href="/quick-check">Quick Measure</Nav.Link>
                                            </Nav.Item>
                                        </NavDropdown>
                                        <Nav.Item as="li">
                                            <Nav.Link href="/blog">Health Tips</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                            <Nav.Link href="/about">About Me</Nav.Link>
                                        </Nav.Item>
                                    </ul>
                                </Nav>
                            </Navbar.Collapse>
                        </Container>
                    </Navbar>


                    {/* <nav id="navbar" className="navbar">
                        <ul>
                            <li><a className="active" href="index.html">Home</a></li>
                            <li><a href="about.html">About</a></li>
                            <li><a href="services.html">Services</a></li>
                            <li><a href="portfolio.html">Portfolio</a></li>
                            <li><a href="team.html">Team</a></li>
                            <li><a href="pricing.html">Pricing</a></li>
                            <li><a href="blog.html">Blog</a></li>
                            <li className="dropdown"><a href="#"><span>Drop Down</span> <i className="bi bi-chevron-down"></i></a>
                                <ul>
                                    <li><a href="#">Drop Down 1</a></li>
                                    <li className="dropdown"><a href="#"><span>Deep Drop Down</span> <i className="bi bi-chevron-right"></i></a>
                                        <ul>
                                            <li><a href="#">Deep Drop Down 1</a></li>
                                            <li><a href="#">Deep Drop Down 2</a></li>
                                            <li><a href="#">Deep Drop Down 3</a></li>
                                            <li><a href="#">Deep Drop Down 4</a></li>
                                            <li><a href="#">Deep Drop Down 5</a></li>
                                        </ul>
                                    </li>
                                    <li><a href="#">Drop Down 2</a></li>
                                    <li><a href="#">Drop Down 3</a></li>
                                    <li><a href="#">Drop Down 4</a></li>
                                </ul>
                            </li>
                            <li><a href="contact.html">Contact</a></li>
                        </ul>
                        <i className="bi bi-list mobile-nav-toggle"></i>
                    </nav> */}

                </div>
            </header>

        </>
    );
}

export default Header;