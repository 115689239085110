import React, { useState } from "react";
import { createImmediatelyInvokedFunctionExpression } from "typescript";

export const CalorieCalc = () => {
    const [calorie, setCalorie] = useState(0);

    interface FormDataType { height: number, weight: number, age: number; gender: string, activity: number };

    const formData: FormDataType = { height: 0, weight: 0, age: 2, gender: "Male", activity: 1.465 };

    const [responseBody, setResponseBody] = useState<FormDataType>(formData);


    const inputChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { id, value } = event.target
        setResponseBody({ ...responseBody, [id]: value })
    };

    const selectChangeHandler = (event: any) => {
        const { name, value } = event.target
        setResponseBody({ ...responseBody, [name]: value })
    };
    const onSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        console.log(responseBody);
        let gender = responseBody.gender;

        if (gender == "Male")
            CalorieCalc(13.397, 4.799, 5.677, 88.362, 0);
        else
            CalorieCalc(9.247, 3.098, 4.330, 447.593, 0);
        //Form submission happens here
    };

    const CalorieCalc = (wm: number, hm: number, am: number, f: number, add: number) => {

        let w = Number(responseBody.weight) * wm;
        let h = Number(responseBody.height) * hm;

        let a = Number(responseBody.age) * am;

        let r = w + h;

        r = r - a;

        r = r + f + add;

        r = r * Number(responseBody.activity);

        setCalorie(r);
    };

    return (
        <>
            <div className="card">
                <div className="card-header">
                    <h2>Calories Intake</h2>
                </div>
                <div className="card-body">
                    <div className="row g-3">
                        <form className="col-md-6" onSubmit={onSubmitHandler}>
                            <div className="row mb-3">
                                <label className="col-sm-3 col-form-label col-form-label-sm">Age</label>
                                <div className="col-sm-9">
                                    <input type="number" className="form-control" id="age" min={2} max={120} onChange={(e) => inputChangeHandler(e)} />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label className="col-sm-3 col-form-label col-form-label-sm">Gender</label>
                                <div className="col-sm-9">
                                    <select id="gender" name="gender" className="form-select" onChange={(e) => selectChangeHandler(e)}>
                                        <option>Male</option>
                                        <option>Female</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label className="col-sm-3 col-form-label col-form-label-sm">Height (cm)</label>
                                <div className="col-sm-9">
                                    <input type="number" className="form-control" id="height" onChange={(e) => inputChangeHandler(e)} />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label className="col-sm-3 col-form-label col-form-label-sm">Weight (kg)</label>
                                <div className="col-sm-9">
                                    <input type="number" className="form-control" id="weight" onChange={(e) => inputChangeHandler(e)} />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label className="col-sm-3 col-form-label col-form-label-sm">Activity</label>
                                <div className="col-sm-9">
                                    <select id="activity" name="activity" className="form-select" onChange={(e) => selectChangeHandler(e)}>
                                        <option value="1" >Basal Metabolic Rate (BMR)</option>
                                        <option value="1.2" >Sedentary: little or no exercise</option>
                                        <option value="1.375" >Light: exercise 1-3 times/week</option>
                                        <option value="1.465" selected>Moderate: exercise 4-5 times/week</option>
                                        <option value="1.55" >Active: daily exercise or intense exercise 3-4 times/week</option>
                                        <option value="1.725" >Very Active: intense exercise 6-7 times/week</option>
                                        <option value="1.9" >Extra Active: very intense exercise daily, or physical job</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-12 d-flex justify-content-end">
                                <button type="submit" className="btn btn-primary">Calculate</button>
                            </div>
                        </form>
                        <div className="col-md-6 border border-black">
                            { calorie > 0 ?
                                <div className="card" style={{marginTop: 30}}>
                                    <div className="card-body">
                                        <h5 className="fs-1 badge bg-success">{calorie.toFixed(0)}</h5>
                                        <h6>calories/day</h6>
                                        <p>To maintain same body weight, daily {calorie.toFixed(0)} calories is recommended. </p>
                                        <p><a href="/contact">Click here</a> for consultation on weight loss/gain without compromising on nutrition.</p>
                                    </div>
                                </div>
                                : ''
                            }
                        </div>
                    </div>

                </div>


            </div>
        </>
    )
}