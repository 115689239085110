import React, { useState } from "react";
import GaugeComponent from 'react-gauge-component';

export const BmiCalc = () => {
    const [bmi, setBmi] = useState(0);

    interface FormDataType { height: number, weight: number, age: number; gender: string };

    const formData: FormDataType = { height: 0, weight: 0, age: 2, gender: "Male" };

    const [responseBody, setResponseBody] = useState<FormDataType>(formData);

    const inputChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { id, value } = event.target
        setResponseBody({ ...responseBody, [id]: value })
    };

    const selectChangeHandler = (event: any) => {
        const { name, value } = event.target
        setResponseBody({ ...responseBody, [name]: value })
    };
    const onSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()


        let w = Number(responseBody.weight);
        let h = Number(responseBody.height) / 100;

        let th = h * h;

        let bmi = (w / th);
        setBmi(bmi);
        //Form submission happens here
    };

    return (
        <>
            <div className="card">
                <div className="card-header">
                    <h2>Body Mass Index</h2>
                </div>
                <div className="card-body">
                    <div className="row g-3">
                        <form className="col-md-6" onSubmit={onSubmitHandler}>
                            <div className="row mb-3">
                                <label className="col-sm-6 col-form-label">Age</label>
                                <div className="col-sm-6">
                                    <input type="number" className="form-control" id="age" min={2} max={120} onChange={(e) => inputChangeHandler(e)} />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label className="col-sm-6 col-form-label">Gender</label>
                                <div className="col-sm-6">
                                    <select id="gender" name="gender" className="form-select" onChange={(e) => selectChangeHandler(e)}>
                                        <option>Male</option>
                                        <option>Female</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label className="col-sm-6 col-form-label">Height (cm)</label>
                                <div className="col-sm-6">
                                    <input type="number" className="form-control" id="height" onChange={(e) => inputChangeHandler(e)} />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label className="col-sm-6 col-form-label">Weight (kg)</label>
                                <div className="col-sm-6">
                                    <input type="number" className="form-control" id="weight" onChange={(e) => inputChangeHandler(e)} />
                                </div>
                            </div>
                            <div className="col-12 d-flex justify-content-end">
                                <button type="submit" className="btn btn-primary">Calculate</button>
                            </div>
                        </form>
                        <div className="col-md-6 border border-black">
                            <GaugeComponent
                                type="semicircle"
                                style={{width: 450 }}
                                arc={{
                                    width: 0.2,
                                    padding: 0.005,
                                    cornerRadius: 1,
                                    // gradient: true,
                                    subArcs: [
                                        {
                                            limit: 16,
                                            color: '#f7160a',
                                            showTick: true,
                                            tooltip: {
                                                text: 'Severe Thinness'
                                            },
                                        },
                                        {
                                            limit: 17,
                                            color: '#f5704c',
                                            showTick: true,
                                            tooltip: {
                                                text: 'Moderate Thinness'
                                            }
                                        },
                                        {
                                            limit: 18.5,
                                            color: '#f6fa07',
                                            showTick: true,
                                            tooltip: {
                                                text: 'Mild Thinness'
                                            }
                                        },
                                        {
                                            limit: 23,
                                            color: '#0feb0c',
                                            showTick: true,
                                            tooltip: {
                                                text: 'Normal'
                                            }
                                        },
                                        {
                                            limit: 25,
                                            color: '#0fab0c',
                                            showTick: true,
                                            tooltip: {
                                                text: 'At Risk'
                                            }
                                        },
                                        {
                                            limit: 30,
                                            color: '#f6fa07',
                                            showTick: true,
                                            tooltip: {
                                                text: 'Overweight'
                                            }
                                        },
                                        {
                                            limit: 35,
                                            color: '#f5704c',
                                            showTick: true,
                                            tooltip: {
                                                text: 'Obese Class I'
                                            }
                                        },
                                        {
                                            limit: 40,
                                            color: '#f7160a',
                                            showTick: true,
                                            tooltip: {
                                                text: 'Obese Class II'
                                            }
                                        },
                                        {
                                            limit: 50,
                                            color: '#730202',
                                            showTick: false,
                                            tooltip: {
                                                text: 'Obese Class III'
                                            }
                                        }
                                    ]
                                }}
                                pointer={{
                                    color: '#021773',
                                    length: 0.80,
                                    width: 10,
                                    // elastic: true,
                                }}
                                labels={{
                                    valueLabel: { formatTextValue: value => 'BMI = ' + value.toFixed(0), matchColorWithArc: true },
                                    tickLabels: {
                                        type: 'outer',
                                        hideMinMax: true,
                                        defaultTickValueConfig: { style: {fontSize: "14px"}, maxDecimalDigits: 1}
                                    }
                                }}
                                value={bmi}
                                minValue={1}
                                maxValue={50}
                            />

                        </div>
                    </div>

                </div>
                <div className="card-footer">
                    <p>BMI: {bmi.toFixed(2)} kg/m<sup>2</sup></p>
                </div>

            </div>
        </>
    )
}