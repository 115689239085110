import React from "react";

export const Featured = () => {
    return(
        <section id="featured" className="featured">
      <div className="container">

      <div className="section-title">
          <h2>Measure Your Health</h2>
        </div>

        <div className="row">
          <div className="col-lg-4">
            <div className="icon-box">
              <i className="bi bi-card-checklist"></i>
              <h3><a href="/quick-check?s=bmi">Body Mass Index</a></h3>
              <p>BMI is a measurement of a person's leanness or corpulence based on their height and weight, and is intended to quantify tissue mass.</p>
            </div>
          </div>
          <div className="col-lg-4 mt-4 mt-lg-0">
            <div className="icon-box">
              <i className="bi bi-bar-chart"></i>
              <h3><a href="/quick-check?s=cal">Calories Intake</a></h3>
              <p>This Calorie Calculator is based on several equations, and the results of the calculator are based on an estimated average.</p>
            </div>
          </div>
          <div className="col-lg-4 mt-4 mt-lg-0">
            <div className="icon-box">
              <i className="bi bi-binoculars"></i>
              <h3><a href="/quick-check?s=ibw">Ideal Weight</a></h3>
              <p>The Ideal Weight Calculator computes ideal body weight (IBW) ranges based on height, gender, and age.</p>
            </div>
          </div>
        </div>

      </div>
    </section>
    );

}