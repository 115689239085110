import React from 'react';

import './Start.css'
import { HeroCarousel } from '../components/Start/HeroCarousel';
import { Featured } from '../components/Start/Featured';
import { HealthTips } from '../components/Start/HealthTips';
import { Speciality } from '../components/Start/Speciality';

export const Start = () => {
    return (
        <>
            <HeroCarousel></HeroCarousel>

            <main id="main">
                <Featured></Featured>

                <HealthTips></HealthTips>

                <Speciality></Speciality>
            </main>
        </>
    );
}