import React from 'react';
import logo from './logo.svg';
import './App.css';

import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Topbar from './components/Topbar';
import Header from './components/Header';
import Footer from './components/Footer';

import { QuickCheck } from './pages/QuickCheck';
import { Start } from './pages/Start';
import { Contact } from './pages/Contact';


function App() {
  return (
    <>
      <Topbar />

      <Header></Header>

      <BrowserRouter>
      <Routes>
        <Route path="/" element={<Start/>}></Route>
        <Route path="/quick-check" element={<QuickCheck/>}></Route>
        {/* <Route path="/about" element={<About/>}></Route>
        <Route path="/gen-tools" element={<PublicTools/>}></Route>
        <Route path="/pro-tools" element={<ProTools/>}></Route> */}
        <Route path="/contact" element={<Contact/>}/>
      </Routes>
      </BrowserRouter>

      <Footer></Footer>
      <a href="#" className="back-to-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a>
    </>
  );
}

export default App;
