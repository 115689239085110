import React, { useState } from "react";

export const WeightCalc = () => {
    const [weight, setWeight] = useState(0);
    const [weightRange, setWeightRange] = useState("");
    const standaedHeight = 152.4;
    const standardWeightMale = 2.7;
    const standardWeightFemale = 2.2;
    const baseWeightMale = 48;
    const baseWeightFemale = 45.5;

    interface FormDataType { height: number, age: number; gender: string };

    const formData: FormDataType = { height: 0, age: 2, gender: "Male" };

    const [responseBody, setResponseBody] = useState<FormDataType>(formData);

    const inputChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { id, value } = event.target
        setResponseBody({ ...responseBody, [id]: value })
    };

    const selectChangeHandler = (event: any) => {
        const { name, value } = event.target
        setResponseBody({ ...responseBody, [name]: value })
    };
    const onSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        let gender = responseBody.gender;

        if (gender == "Male")
            calculateWeight(152.4, 1.9, 52);
        else
            calculateWeight(152.4, 1.7, 49);

        calculateRange();
        //Form submission happens here
    };

    const calculateWeight = (sh: number, sw: number, bw: number) => {
        let h = Number(responseBody.height) - sh;
        h = h / 2.54;
        let w = h * sw;

        w = w + bw;

        setWeight(w);
    };

    const calculateRange = () => {
        let h = Number(responseBody.height) / 100;
        let startBmi = 18.5;
        let endBmi = 25;

        let th = h * h;

        let sw = (startBmi * th);
        let ew = (endBmi * th);

        setWeightRange(sw.toFixed(1).toString() + " - " + ew.toFixed(1).toString());
    }

    return (
        <>
            <div className="card">
                <div className="card-header">
                    <h2>Ideal Body Weight</h2>
                </div>
                <div className="card-body">

                    <div className="row g-2">
                        <form className="col-md-6" onSubmit={onSubmitHandler}>
                            <div className="row mb-3">
                                <label className="col-sm-3 col-form-label col-form-label-sm">Age</label>
                                <div className="col-sm-9">
                                    <input type="number" className="form-control" id="age" min={2} max={120} onChange={(e) => inputChangeHandler(e)} />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label className="col-sm-3 col-form-label col-form-label-sm">Gender</label>
                                <div className="col-sm-9">
                                    <select id="gender" name="gender" className="form-select" onChange={(e) => selectChangeHandler(e)}>
                                        <option>Male</option>
                                        <option>Female</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label className="col-sm-3 col-form-label col-form-label-sm">Height</label>
                                <div className="col-sm-9">
                                    <input type="number" className="form-control" id="height" onChange={(e) => inputChangeHandler(e)} />
                                </div>
                            </div>

                            <div className="col-12 d-flex justify-content-end">
                                <button type="submit" className="btn btn-primary">Calculate</button>
                            </div>
                        </form>
                        <div className="col-md-6 border border-black">
                            {weight > 0 ?
                                <>
                                    <div className="card" style={{ marginTop: 10 }}>
                                        <div className="card-body">
                                            <h5 className="fs-1 badge bg-success">{weight.toFixed(0)} kg</h5>
                                            <h6>is your ideal body weight accoring to height</h6>
                                            <p><a href="/contact">Consult</a> on maintaing ideal weight</p>
                                        </div>
                                    </div>

                                    <div className="card" style={{ marginTop: 10, marginBottom: 10 }}>
                                        <div className="card-body">
                                            <h5 className="fs-2 badge bg-info">{weightRange} kg</h5>
                                            <h6>is your healthy weight range accoring to height</h6>
                                            <p><a href="/contact">Click here</a> for cunsultation on maintaing weight within healthy range</p>
                                        </div>
                                    </div>
                                </>
                                : ''
                            }
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}