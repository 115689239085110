import React, { useEffect, useState } from 'react';
import { BmiCalc } from '../components/Tools/BmiCalc';
import { CalorieCalc } from '../components/Tools/CalorieCalc';
import { WeightCalc } from '../components/Tools/WeightCalc';
import { useSearchParams } from 'react-router-dom';

export const QuickCheck = () => {
    const [showBmi, SetShowBmi] = useState(true);
    const [showCalorie, SetShowCalorie] = useState(false);
    const [showWeight, SetShowWeight] = useState(false);

    const [params, setParams] = useSearchParams();

    const ShowBmi = () => {
        SetShowBmi(true);
        SetShowCalorie(false);
        SetShowWeight(false);
    }

    const ShowCalorie = () => {
        SetShowBmi(false);
        SetShowCalorie(true);
        SetShowWeight(false);
    }

    const ShowWeight = () => {
        SetShowBmi(false);
        SetShowCalorie(false);
        SetShowWeight(true);
    }

    useEffect(() => {
        let s = params.get('s');
        if(s == null)
            console.log("no param");
        else 
        {
            console.log(s);

            switch(s) {
                case "bmi":
                    ShowBmi();
                    break;
                case "cal":
                    ShowCalorie();
                    break;
                case "ibw":
                    ShowWeight();
                    break;
            }
        }
    },[]);

    return (
        <>
            <section id="breadcrumbs" className="breadcrumbs">
                <div className="container">
                    <h2>Quick Measures</h2>

                </div>
            </section>

            <section id="blog" className="blog">
                <div className="container" data-aos="fade-up">

                    <div className="row">
                        <div className="col-lg-4">
                            <div className="card">
                                <div className="card-header">

                                    <h3 className="sidebar-title">Tools</h3>
                                </div>
                                <div className="card-body">
                                    <ul className="list-group fs-2">
                                        <li className="list-group-item" onClick={() => ShowBmi()}><a className="link-underline-primary">Body Mass Index</a></li>
                                        <li className="list-group-item" onClick={() => ShowCalorie()}><a className="link-underline-primary">Calories Intake</a></li>
                                        <li className="list-group-item" onClick={() => ShowWeight()}><a className="link-underline-primary">Ideal Weight</a></li>
                                    </ul>
                                </div>
                            </div>

                        </div>

                        <div className="col-lg-8 fs-4">
                        {showBmi ? <BmiCalc/> : null}
                        {showCalorie ? <CalorieCalc/> : null}
                        {showWeight ? <WeightCalc/> : null}
                            

                        </div>

                    </div>
                </div>
            </section>
        </>
    );
}