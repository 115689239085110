import React from "react";
import { IconBox } from "../IconBox";

export const Speciality = () => {
    return (
        <section id="services" className="services">
            <div className="container">

                <div className="section-title">
                    <h2>Speciality</h2>
                </div>

                <div className="row">
                    <IconBox title="Cardiac Patients" content="something"></IconBox>

                    <IconBox title="Diabetic Patients" content="something"></IconBox>

                    <IconBox title="Obesity Patients" content="something"></IconBox>
                </div>
            </div>
        </section>
    );
}