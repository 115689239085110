import React from "react";

export const HealthTips = () => {
    return(
        <section id="about" className="about">
      <div className="container">

      <div className="section-title">
          <h2>Quick Tips</h2>
        </div>

        <div className="row">
          <div className="col-lg-6">
            <img src="assets/img/healthtips.jpg" className="img-fluid" alt="" />
          </div>
          <div className="col-lg-6 pt-4 pt-lg-0 content">
            <h3>Nutritional Tips to follow</h3>
            <p className="fst-italic">
              
            </p>
            <ul>
              <li><i className="bi bi-check-circle"></i> Follow a healthy food habbits.</li>
              <li><i className="bi bi-check-circle"></i> Follow a fixed meal timmings.</li>
              <li><i className="bi bi-check-circle"></i> Eat good amount protein  in every meal.</li>
              <li><i className="bi bi-check-circle"></i> Reduce salt intake. Avoid excess salty foods.</li>
              <li><i className="bi bi-check-circle"></i> Eat fibre rich foods.</li>
              <li><i className="bi bi-check-circle"></i> Add seeds and nuts in diet.</li>
              <li><i className="bi bi-check-circle"></i> Avoid frequent munching.</li>
              <li><i className="bi bi-check-circle"></i> Sleep well.</li>
            </ul>
            <p>
              
            </p>
          </div>
        </div>

      </div>
    </section>
    );
}