import React from "react";

import { Carousel } from 'react-bootstrap';


export const HeroCarousel = () => {
    return (
        <section id="hero">
            <Carousel fade className='hero-carousel' id="hero-carousel">

                <Carousel.Item className='carousel-inner' style={{ backgroundImage: "url(assets/img/slide/slide-1.jpg)" }}>
                    <div className="carousel-container">
                        <div className="carousel-content">
                            <h2 className="animate__animated animate__fadeInDown">Hi, I'm <span>Suparna</span></h2>
                            <p className="animate__animated animate__fadeInUp">I'm a Registered Dietitian and a proud gluten free mother. And I believe that feeding a child or a patient in a healthy way is always nutritionally challanged work. Here you will find easy to follow guidelines , healthy tips , science backed answers to your un answered questions about food and nutrition. I am so happy 😊 to see you here.</p>
                            <a href="/home" className="btn-get-started animate__animated animate__fadeInUp">Learn More<i className="fa fa-arrow-right"></i></a>
                        </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item className='carousel-inner' style={{ backgroundImage: "url(assets/img/slide/slide-2.jpg)" }}>
                    <div className="carousel-container">
                        <div className="carousel-content">
                            <h2 className="animate__animated fanimate__adeInDown">Suparna's <span>Belief</span></h2>
                            <p className="animate__animated animate__fadeInUp">I believe that feeding a child or a patient in a healthy way is always nutritionally challanged work. Here you will find easy to follow guidelines, healthy tips, science backed answers to your un answered questions about food and nutrition.</p>
                            <a href="/home" className="btn-get-started animate__animated animate__fadeInUp">Learn More<i className="fa fa-arrow-right"></i></a>
                        </div>
                    </div>
                </Carousel.Item>
            </Carousel>

            <a className="carousel-control-prev" href="#heroCarousel" role="button" data-bs-slide="prev">
                <span className="carousel-control-prev-icon bi bi-chevron-left" aria-hidden="true"></span>
            </a>

            <a className="carousel-control-next" href="#heroCarousel" role="button" data-bs-slide="next">
                <span className="carousel-control-next-icon bi bi-chevron-right" aria-hidden="true"></span>
            </a>
        </section>
    );
}